import crudGetters from "@/store/templates/b-crud/getters";
import { IS_CHANGED } from "@/store/templates/b-crud/getter-types";
import { isChanged } from "@/store/change";

export default {
  ...crudGetters,
  [IS_CHANGED]: (state, getters) =>
    isChanged(state.model, state.initialModel) ||
    getters[`ruleTriggerGroups/${IS_CHANGED}`],
  refinementRule: state => state.model,
  id: state => state.model.id,
  name: state => state.model.name,
  targets: state => state.model.targets,
  refinements: state => state.model.refinements,
  refinementType: state => refinementIndex =>
    state.model.refinements[refinementIndex].type,
  refinementBeta: state => refinementIndex =>
    state.model.refinements[refinementIndex].beta,
  refinementAlpha: state => refinementIndex =>
    state.model.refinements[refinementIndex].alpha,
  isActive: state => state.model.isActive,
  refinementIsCaseSensitive: state => refinementIndex =>
    state.model.refinements[refinementIndex].isCaseSensitive
};
